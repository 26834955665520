<template>
  <card>
    <div class="d-flex pointer row">
      <div class="col-4">
        <img :src="pressRelease.image" />
      </div>
      <div class="col-8 d-flex flex-column justify-content-center">
        <div class="pr-title">
          <p class="pr-title-id">TITLE</p>
          <h5 class="font-weight-bold">{{ pressRelease.title }}</h5>
        </div>
        <p class="pr-date">{{ pressRelease.created_at }}</p>
      </div>
    </div>
  </card>
</template>

<script>
export default {
  props: {
    pressRelease: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
.pr-title-id {
  font-size: 10px;
  margin-bottom: 1px;
  font-weight: 400;
}

.pr-title {
  margin-bottom: 4px;
}

.pr-title h5 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  margin-bottom: 0;
}
.pr-date {
  font-size: 12px;
  font-weight: 500;
  margin-top: 0;
}
.button {
  background-color: #389466;
  padding: 5px 10px;
  color: white;
  border-radius: 5px;
  margin-top: 4px;
}

i:hover {
  color: white;
}
</style>
