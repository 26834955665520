<template>
  <card class="p-2" v-if="model">
    <h3 slot="header" class="title">{{ action }} Press Release</h3>
    <div class="row">
      <div class="col-md-4 uploadable banner-image">
        <label
          for="logo_upload"
          class="upload d-flex align-items-center justify-content-center"
        >
          <p>Click to upload</p>
          <input
            @change="uploadPhoto"
            type="file"
            class="d-none"
            id="logo_upload"
          />
        </label>
        <img :src="photo" />
      </div>
      <div class="col-md-8">
        <base-input label="Title" placeholder="Title" v-model="model.title">
        </base-input>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-md-12 mx-auto">
        <base-input class="mt-4">
          <label>Content</label>
          <textarea
            rows="7"
            cols="80"
            class="form-control"
            v-model="model.content"
          ></textarea>
        </base-input>
      </div>
    </div>
    <base-button
      v-if="model.type == 'add'"
      @click="create"
      slot="footer"
      type="secondary"
      fill
      >Save</base-button
    >
    <base-button
      v-if="model.type != 'add'"
      @click="update"
      slot="footer"
      type="secondary"
      fill
      >Update</base-button
    >
    <base-button
      v-if="model.type != 'add'"
      @click="deletePressRelease"
      slot="footer"
      type="warning"
      class="ml-4"
      fill
      >Delete</base-button
    >
  </card>
  <card v-else>
    <div
      class="d-flex flex-column align-items-center justify-content-center full user-select-none"
    >
      <i class="tim-icons icon-alert-circle-exc text"></i>
      <h4 class="text-uppercase text-center font-weight-bold my-4">
        Select a Press Release to view details
      </h4>
    </div>
  </card>
</template>
<script>
import store from "../../store";
export default {
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      image: null,
      newImage: null,
      newImageURL: null,
    };
  },
  mounted() {
    this.image = this.model?.image;
  },
  computed: {
    photo: {
      get() {
        return this.image;
      },
      set(newValue) {
        this.image = newValue;
      },
    },
    action() {
      return this.model.type == "add" ? "Add" : "Edit";
    },
  },
  watch: {
    model(newModel) {
      if (newModel.type == "add") {
        this.image = this.newImageURL || newModel.image;
      } else {
        this.image = newModel.image;
      }
    },
  },
  methods: {
    create() {
      const formData = new FormData();
      if (this.newImage) {
        formData.append("image", this.newImage);
        formData.append("title", this.model.title);
        formData.append("content", this.model.content);

        store
          .dispatch("createPressRelease", formData)
          .then((response) => {
            this.$emit("clearModel");
            location.reload();
            this.$notify({
              message: response.data.message,
              icon: "tim-icons icon-bell-55",
              horizontalAlign: "left",
              verticalAlign: "bottom",
              type: "success",
              timeout: 0,
            });
          })
          .catch((error) => {
            this.$notify({
              message: error.response.data.message,
              icon: "tim-icons icon-bell-55",
              horizontalAlign: "left",
              verticalAlign: "bottom",
              type: "warning",
              timeout: 0,
            });
          });
      } else {
        alert("Please upload an image to continue");
      }
    },
    update() {
      store
        .dispatch("updatePressRelease", this.model)
        .then((response) => {
          this.$emit("clearModel");
          location.reload();
          this.$notify({
            message: response.data.message,
            icon: "tim-icons icon-bell-55",
            horizontalAlign: "left",
            verticalAlign: "bottom",
            type: "success",
            timeout: 0,
          });
        })
        .catch((error) => {
          this.$notify({
            message: error.response.data.message,
            icon: "tim-icons icon-bell-55",
            horizontalAlign: "left",
            verticalAlign: "bottom",
            type: "warning",
            timeout: 0,
          });
        });
    },
    uploadPhoto(event) {
      const photo = event.target.files[0];
      this.photo = URL.createObjectURL(photo);
      this.newImageURL = this.photo;

      if (this.model.type != "add") {
        const formData = new FormData();
        formData.append("id", this.model.id);
        formData.append("image", photo);

        store
          .dispatch("updatePressReleasePhoto", formData)
          .then((response) => {
            this.$notify({
              message: response.data.message,
              icon: "tim-icons icon-bell-55",
              horizontalAlign: "left",
              verticalAlign: "bottom",
              type: "success",
              timeout: 0,
            });
          })
          .catch((error) => {
            this.$notify({
              message: error.response.data.message,
              icon: "tim-icons icon-bell-55",
              horizontalAlign: "left",
              verticalAlign: "bottom",
              type: "warning",
              timeout: 0,
            });
          });
      } else {
        this.newImage = photo;
      }
    },
    deletePressRelease() {
      const decision = window.confirm(
        "Are you sure you want to delete this press release?"
      );
      if (decision) {
        store
          .dispatch("deletePressRelease", this.model)
          .then((response) => {
            this.$emit("clearModel");
            this.$notify({
              message: response.data.message,
              icon: "tim-icons icon-bell-55",
              horizontalAlign: "left",
              verticalAlign: "bottom",
              type: "success",
              timeout: 0,
            });
          })
          .catch((error) => {
            this.$notify({
              message: error.response.data.message,
              icon: "tim-icons icon-bell-55",
              horizontalAlign: "left",
              verticalAlign: "bottom",
              type: "warning",
              timeout: 0,
            });
          });
      }
    },
  },
};
</script>
<style>
.banner-image {
  height: 100px;
}
.banner-image img {
  width: 100%;
}
</style>
