<template>
  <div class="row">
    <div class="col-md-8 full-height">
      <div class="row">
        <div
          class="col-md-4"
          @click="selectPublication(publication)"
          v-for="publication in investorSupportServices"
          :key="publication.id"
        >
          <publication-card :publication="publication"></publication-card>
        </div>
      </div>
    </div>
    <div class="col-md-4" v-if="investorSupportServices">
      <div class="mb-4 d-flex align-items-center justify-content-between">
        <base-button @click="refresh" slot="footer" type="secondary" fill
          >Refresh</base-button
        >
        <base-button @click="add" slot="footer" type="secondary" fill
          >Add New</base-button
        >
      </div>
      <edit-publication-form @clearModel="clearModelData" :model="model"> </edit-publication-form>
    </div>
  </div>
</template>
<script>
import EditPublicationForm from "./Publication/EditPublicationForm";
import PublicationCard from "./Publication/PublicationCard";
import store from "../store";

export default {
  components: {
    EditPublicationForm,
    PublicationCard,
  },
  data() {
    return {
      model: null
    };
  },
  mounted() {
    store.dispatch("getAllSupportServices");
  },
  methods: {
    selectPublication(publication) {
      this.model = publication;
    },
    add() {
      this.model = { type: "add" };
    },
    refresh() {
      store.dispatch("getAllSupportServices");
    },
    clearModelData() {
      this.model = null;
    }
  },
  computed: {
    investorSupportServices() {
      return store.state.investorSupportServicesModule.investorSupportServices;
    }
  }
};
</script>
<style></style>
