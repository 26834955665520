<template>
  <div class="row">
    <div class="col-md-5 full-height">
      <div
        @click="selectPressRelease(pressRelease)"
        v-for="pressRelease in pressReleases"
        :key="pressRelease.id"
      >
        <press-release-card :pressRelease="pressRelease"></press-release-card>
      </div>
    </div>
    <div class="col-md-7" v-if="pressReleases">
      <div class="mb-4 d-flex align-items-center justify-content-between">
        <base-button @click="refresh" slot="footer" type="secondary" fill
          >Refresh</base-button
        >
        <base-button @click="add" slot="footer" type="secondary" fill
          >Add New</base-button
        >
      </div>
      <edit-press-release-form @clearModel="clearModelData" :model="model">
      </edit-press-release-form>
    </div>
  </div>
</template>
<script>
import EditPressReleaseForm from "./PressRelease/EditPressReleaseForm";
import PressReleaseCard from "./PressRelease/PressReleaseCard";
import store from "../store/index";
import nasidaLogo from "../assets/logo.svg";

export default {
  components: {
    EditPressReleaseForm,
    PressReleaseCard,
  },
  data() {
    return {
      model: null,
    };
  },
  mounted() {
    store.dispatch("getAllPressReleases", 1);
  },
  methods: {
    selectPressRelease(pressRelease) {
      this.model = pressRelease;
    },
    refresh() {
      location.reload()
    },
    add() {
      this.model = { type: "add", image: nasidaLogo };
    },
    clearModelData() {
      this.model = null;
    },
  },
  computed: {
    pressReleases() {
      return store.state.pressReleasesModule.pressReleases;
    },
  },
};
</script>
<style></style>
