import { render, staticRenderFns } from "./PressReleaseCard.vue?vue&type=template&id=14f1b1f6&scoped=true&"
import script from "./PressReleaseCard.vue?vue&type=script&lang=js&"
export * from "./PressReleaseCard.vue?vue&type=script&lang=js&"
import style0 from "./PressReleaseCard.vue?vue&type=style&index=0&id=14f1b1f6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14f1b1f6",
  null
  
)

export default component.exports