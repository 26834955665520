<template>
  <div class="mt-4">
    <div class="col-md-4 mx-auto">
      <card class="px-4 py-2 rounded-3">
        <base-input
          class="mb-4"
          label="Email"
          placeholder="Email"
          type="email"
          v-model="email"
        >
        </base-input>
        <base-input
          label="Password"
          placeholder="Password"
          type="password"
          v-model="password"
          v-on:keyup.enter="login()"
        >
        </base-input>
        <div class="mt-4 w-100" @click="login()">
          <base-button>Login</base-button>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import store from "../store/index";

export default {
  data() {
    return {
      email: null,
      password: null,
    };
  },
  computed: {
    device_name() {
      // console.log(navigator.userAgentData.platform);
      return navigator.userAgentData.platform;
    },
  },
  methods: {
    login() {
      store
        .dispatch("login", {
          email: this.email,
          password: this.password,
          device_name: this.device_name,
        })
        .then((_) => {
          this.$router.push({ name: "Press Releases" });
          this.$notify({
            message: _.data.message,
            icon: "tim-icons icon-bell-55",
            horizontalAlign: "left",
            verticalAlign: "bottom",
            type: "success",
            timeout: 0,
          });
        })
        .catch((error) => {
          this.$notify({
            message: error.response.data.message,
            icon: "tim-icons icon-bell-55",
            horizontalAlign: "left",
            verticalAlign: "bottom",
            type: "warning",
            timeout: 0,
          });
        });
    },
  },
};
</script>
<style></style>
