<template>
  <card>
    <div class="d-flex pointer flex-column">
      <iframe
        :src="publication.link + '#toolbar=0'"
        height="200px"
        width="100%"
        frameborder="0"
      ></iframe>
      <p class="mt-4 font-weight-bold">{{ publication.name }}</p>
    </div>
  </card>
</template>

<script>
export default {
  props: {
    publication: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
};
</script>

<style>
.pointer {
  cursor: pointer;
}
.button {
  background-color: #389466;
  padding: 5px 10px;
  color: white;
  border-radius: 5px;
  margin-top: 4px;
}

i:hover {
  color: white;
}
</style>
