<template>
  <card class="p-2" v-if="model">
    <h3 slot="header" class="title mb-0">{{ action }} Publication</h3>
    <div class="row">
      <div class="col-md-6">
        <base-input
          label="Publication Name"
          placeholder="Publication Name"
          v-model="model.name"
        >
        </base-input>
      </div>
      <div class="col-md-6">
        <base-input
          label="Publication URL"
          placeholder="Publication URL"
          v-model="model.link"
        >
        </base-input>
      </div>
    </div>
    <base-button
      v-if="model.type == 'add'"
      @click="create"
      slot="footer"
      type="secondary"
      fill
      >Save</base-button
    >
    <base-button
      v-if="model.type != 'add'"
      @click="update"
      slot="footer"
      type="secondary"
      fill
      >Update</base-button
    >
    <base-button
      v-if="model.type != 'add'"
      @click="deletePublication"
      slot="footer"
      type="warning"
      class="ml-4"
      fill
      >Delete</base-button
    >
  </card>
  <card v-else>
    <div
      class="d-flex flex-column align-items-center justify-content-center full user-select-none"
    >
      <i class="tim-icons icon-alert-circle-exc text"></i>
      <h4 class="text-uppercase text-center font-weight-bold my-4">
        Select a Publication to view details
      </h4>
    </div>
  </card>
</template>
<script>
import store from "../../store";
export default {
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  computed: {
    action() {
      return this.model.type == "add" ? "Add" : "Edit";
    }
  },
  methods: {
    create() {
      store
        .dispatch("createSupportService", this.model)
        .then(response => {
          this.$emit("clearModel");
          this.$notify({
            message: response.data.message,
            icon: "tim-icons icon-bell-55",
            horizontalAlign: "left",
            verticalAlign: "bottom",
            type: "success",
            timeout: 0
          });
        })
        .catch(error => {
          this.$notify({
            message: error.response.data.message,
            icon: "tim-icons icon-bell-55",
            horizontalAlign: "left",
            verticalAlign: "bottom",
            type: "warning",
            timeout: 0
          });
        });
    },
    update() {
      store
        .dispatch("updateSupportService", this.model)
        .then(response => {
          this.$emit("clearModel");
          this.$notify({
            message: response.data.message,
            icon: "tim-icons icon-bell-55",
            horizontalAlign: "left",
            verticalAlign: "bottom",
            type: "success",
            timeout: 0
          });
        })
        .catch(error => {
          this.$notify({
            message: error.response.data.message,
            icon: "tim-icons icon-bell-55",
            horizontalAlign: "left",
            verticalAlign: "bottom",
            type: "warning",
            timeout: 0
          });
        });
    },
    deletePublication() {
      const decision = window.confirm(
        "Are you sure you want to delete this publication?"
      );
      if (decision) {
        store
          .dispatch("deleteSupportService", this.model)
          .then(response => {
            this.$emit("clearModel");
            this.$notify({
              message: response.data.message,
              icon: "tim-icons icon-bell-55",
              horizontalAlign: "left",
              verticalAlign: "bottom",
              type: "success",
              timeout: 0
            });
          })
          .catch(error => {
            this.$notify({
              message: error.response.data.message,
              icon: "tim-icons icon-bell-55",
              horizontalAlign: "left",
              verticalAlign: "bottom",
              type: "warning",
              timeout: 0
            });
          });
      }
    }
  }
};
</script>
<style></style>
